'use client';

import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import {
  useGetAllArticlesPaginatedMutation,
  useGetAllArticlesQuery,
  useGetArticlesByCategoryIdQuery,
} from '@/RTK/services/articleApi';
import {
  useGetCategoriesByIdQuery,
  useGetCategoriesQuery,
  useToggleCategoryMutation,
} from '@/RTK/services/categoryApi';
import { useGetUserQuery } from '@/RTK/services/userApi';
import {
  ChevronLeft,
  ChevronRight,
  Lightbulb,
  PencilRuler,
  Plus,
  SquarePen,
} from 'lucide-react';
import { IoClose } from 'react-icons/io5';
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

import { Button } from '@/components/ui/button';

import { pen } from '../../../public/images';
import Loader from '../../utils/Loader';
import ExploreArticleCard from './(dashboard)/components/ExploreArticleCard';
import MobileNavbar from './(dashboard)/components/MobileNavbar';
import Navbar from './(dashboard)/components/Navbar';
import { ExploreArticleCardSkeleton } from './components/ExploreArticleCardSkeleton';
import { SkeletonLoader } from './components/HomePageSekelton';
import ArticlesSkeletonLoader from './components/SkeletonLoader';

const HomePage = () => {
  const [isSticky, setIsSticky] = useState(false); // Track sticky state
  const sliderRef = useRef(null);
  const router = useRouter();
  const { token, user } = useSelector((state) => state.auth);
  const { data: userData, isLoading: isUserLoading } = useGetUserQuery(
    undefined,
    {
      skip: !token,
    }
  );

  const [selectedInterest, setSelectedInterest] = useState(null);
  const { data: defaultCategories, isLoadingCategories } =
    useGetCategoriesQuery();
  const { data: filteredArticles, isLoading: loadingFilteredArticles } =
    useGetArticlesByCategoryIdQuery(
      { selectedInterest, userId: user?._id },
      {
        skip: !selectedInterest, // Skip the query if no interest is selected
      }
    );

  const [getAllArticlesPaginated] = useGetAllArticlesPaginatedMutation();
  const [open, setOpen] = useState(false);
  const [openTopicModal, setOpenTopicModal] = useState(false);
  const { data, isLoading } = useGetAllArticlesQuery();
  const [toggleCategory] = useToggleCategoryMutation();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const interestData = defaultCategories || [];

  const [paginatedArticles, setPaginatedArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Get a subset of categories (e.g., first 12)
  const displayedCategories = defaultCategories?.slice(0, 12) || [];

  const handleInterestSelect = (interestId) => {
    setSelectedInterest(interestId); // Set the selected interest directly
  };

  // Scroll event handler
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const stickyThreshold = 130; // Adjust this value as needed

    if (scrollTop > stickyThreshold) {
      setIsSticky(true); // Make slider sticky
    } else {
      setIsSticky(false); // Reset slider to normal
    }
  };

  const handleTopicToggle = async (interest) => {
    try {
      await toggleCategory(interest);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log('userData', userData);
    if (!isUserLoading && userData?.user && !userData.user.bio) {
      router.push('/onboarding/picture');
      return;
    }
  }, [userData, isUserLoading, router]);
  const customStyles = {
    content: {
      right: '10%',
      bottom: '10%',
      top: 'auto',
      left: 'auto',
      marginRight: '-20%',
      width: '90%',
      maxWidth: '170px',
      maxHeight: '230px',
      padding: '10px',
      overflowY: 'auto',
      transform: 'translate(-50%, -50%)',
      scrollbarWidth: 'none',
      background: 'white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    rtl: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          speed: 200,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          speed: 200,
        },
      },
    ],
    beforeChange: (oldSlide, newSlide) => handleSlideChange(newSlide),
  };

  const fetchArticles = async () => {
    const response = await getAllArticlesPaginated({ page, limit: 3 });
    console.log('data', response.data.articles);

    if (response.data.articles.length === 0) {
      setHasMore(false);
    } else {
      setPaginatedArticles((prev) => [...prev, ...response?.data?.articles]);
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    fetchArticles();
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleSlideChange = (currentSlide) => {
    // Check if we're at the start
    setShowLeftArrow(currentSlide > 0);

    // Check if we're at the end
    // We need to calculate if we're near the end based on total slides
    const totalSlides = interestData?.length || 0;
    const slidesToShow = settings.slidesToShow;
    setShowRightArrow(currentSlide < totalSlides - slidesToShow);
  };
  // Add this query to get user's followed categories
  const { data: categories } = useGetCategoriesByIdQuery(undefined, {
    skip: !token,
  });
  
  useEffect(() => {
    router.push('/feed');
  }, []);

  return (
    <>
      {isLoading && (
        <div className="h-[70vh] w-full flex justify-center items-center">
          <SkeletonLoader />
        </div>
      )}
      {!isLoading && (
        <main className="flex flex-col w-full">
          <Navbar />
          {token && <MobileNavbar />}

          <div className="flex lg:flex-row flex-col">
            <div
              className={`lg:p-8 md:p-5 p-3 lg:border-l lg:w-2/3  flex flex-col gap-3`}
            >
              <div
                className={`${isSticky ? 'fixed top-0 w-fit z-10 border-b bg-white  ' : ''} transition-all  duration-300`}
                style={{ width: isSticky ? '100%' : 'auto' }}
              >
                <div className="flex items-center flex-row w-full relative md:gap-4 py-2">
                  <button
                    onClick={() => setOpenTopicModal(true)}
                    className="flex-shrink-0 w-8 h-8 rounded-full hover:bg-gray-50 flex items-center justify-center transition-colors group"
                    aria-label="Add topic"
                  >
                    <Plus
                      width={20}
                      height={20}
                      className="text-gray-400 group-hover:text-purple-600 transition-colors"
                    />
                  </button>

                  <div className="relative flex gap-2 w-full items-center">
                    {showLeftArrow && (
                      <button
                        onClick={previous}
                        className="absolute left-0 z-10 flex items-center h-full"
                        aria-label="Previous topics"
                      >
                        <div className="relative flex items-center">
                          <div className="absolute inset-y-0 left-0 w-12 bg-gradient-to-r from-white to-transparent" />
                          <div className="relative w-8 h-8 rounded-full bg-white hover:bg-gray-50 flex items-center justify-center transition-all shadow-sm hover:shadow-md">
                            <ChevronLeft className="w-4 h-4 text-gray-600" />
                          </div>
                        </div>
                      </button>
                    )}

                    <div className="w-full overflow-hidden px-2 ">
                      <Slider
                        ref={sliderRef}
                        {...settings}
                        className="slick-slider-custom "
                      >
                        {[...(interestData || [])].map((interest) => (
                          <button
                            key={interest?._id}
                            onClick={() => handleInterestSelect(interest?._id)}
                            className={`
                              px-3 py-1.5 rounded-full text-sm font-medium
                              border transition-all duration-200
                              ${
                                selectedInterest === interest?._id
                                  ? 'bg-purple-50 text-purple-700 border-purple-200 shadow-sm'
                                  : 'text-gray-600 border-gray-200 hover:border-gray-300 hover:bg-gray-50'
                              }
                            `}
                          >
                            {interest?.name}
                          </button>
                        ))}
                      </Slider>
                    </div>

                    {showRightArrow && (
                      <button
                        onClick={next}
                        className="absolute right-0 z-10 flex items-center h-full"
                        aria-label="Next topics"
                      >
                        <div className="relative flex items-center">
                          <div className="absolute inset-y-0 right-0 w-12 bg-gradient-to-l from-white to-transparent" />
                          <div className="relative w-8 h-8 rounded-full bg-white hover:bg-gray-50 flex items-center justify-center transition-all shadow-sm hover:shadow-md">
                            <ChevronRight className="w-4 h-4 text-gray-600" />
                          </div>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {!selectedInterest ? (
                paginatedArticles?.length === 0 ? (
                  <div className="flex items-center justify-center gap-4">
                    {<ExploreArticleCardSkeleton />}
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 h-[200px]">
                    <InfiniteScroll
                      dataLength={paginatedArticles.length}
                      next={fetchArticles}
                      hasMore={hasMore}
                      loader={<ExploreArticleCardSkeleton />}
                      endMessage={
                        <p className="text-center p-3">
                          لا يوجد المزيد من المقالات للتحميل
                        </p>
                      }
                      refreshFunction={fetchArticles}
                      pullDownToRefresh
                      pullDownToRefreshThreshold={50}
                      pullDownToRefreshContent={
                        <h3 style={{ textAlign: 'center' }}>
                          &#8595; لتحديث الصفحة
                        </h3>
                      }
                      releaseToRefreshContent={
                        <h3 style={{ textAlign: 'center' }}>
                          &#8593;اسحب الشاشة
                        </h3>
                      }
                    >
                      {paginatedArticles?.map((article) => (
                        <ExploreArticleCard
                          key={article?._id}
                          article={article}
                        />
                      ))}
                    </InfiniteScroll>
                  </div>
                )
              ) : filteredArticles?.length === 0 ? (
                <div className="flex items-center justify-center gap-4">
                  <p className="text-[#334155] font-normal text-[14px]">
                    لا يوجد نتائج
                  </p>
                </div>
              ) : loadingFilteredArticles ? (
                <div className="flex justify-center items-center">
                  <ArticlesSkeletonLoader />
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  {filteredArticles?.map((article) => (
                    <ExploreArticleCard key={article?._id} article={article} />
                  ))}
                </div>
              )}
            </div>
            <div className="lg:w-1/3 hidden lg:flex flex-col gap-8 p-8 border-l min-h-screen bg-white">
              {/* Popular Articles Section */}
              <section className="space-y-5">
                <h2 className="text-xl font-semibold text-gray-900 border-b pb-3">
                  آخر المقالات
                </h2>
                {data?.articles.slice(0, 3).map((article) => (
                  <div
                    key={article._id}
                    className="group space-y-2.5 cursor-pointer p-3 rounded-lg hover:bg-gray-50 transition-all duration-200"
                  >
                    <div
                      onClick={() =>
                        router.push(`/@${article?.author?.username}`)
                      }
                      className="flex items-center gap-3 text-gray-600 group/author mb-2"
                    >
                      <div className="relative w-8 h-8 flex-shrink-0">
                        {article?.author?.profileImage ? (
                          <Image
                            src={article?.author?.profileImage}
                            alt={article?.author?.name || 'Author'}
                            fill
                            className="rounded-full object-cover ring-2 ring-offset-1 ring-purple-100 group-hover/author:ring-purple-200 transition-all duration-200"
                          />
                        ) : (
                          <div className="w-full h-full rounded-full bg-gradient-to-br from-purple-50 to-purple-100 ring-2 ring-offset-1 ring-purple-100 flex items-center justify-center">
                            <span className="text-sm font-medium text-purple-700">
                              {article?.author?.name
                                ?.charAt(0)
                                ?.toUpperCase() || 'A'}
                            </span>
                          </div>
                        )}
                      </div>
                      <span className="text-sm font-medium text-purple-700 group-hover/author:text-purple-800">
                        {article?.author?.name || 'Author'}
                      </span>
                    </div>
                    <Link
                      href={`/view-article/${article?._id}`}
                      className="text-base font-medium text-gray-900 group-hover:text-purple-700"
                    >
                      {article?.title}
                    </Link>
                  </div>
                ))}
              </section>

              {/* Resources Section */}
              <section className="bg-gradient-to-br from-purple-50 to-white p-5 rounded-xl space-y-4 border border-purple-100">
                <div className="flex items-center justify-between">
                  <h2 className="font-semibold text-gray-900">مصادر مهمة</h2>
                  {/* <button className="p-1.5 hover:bg-white rounded-full transition-colors">
                    <IoClose className="text-gray-500 hover:text-gray-700 w-5 h-5" />
                  </button> */}
                </div>
                <Link
                  target="_blank"
                  href={'https://maldhaba.com/writing'}
                  className="space-y-3"
                >
                  <p className="text-sm text-gray-700">
                    دورة بريدية مجانية:
                    <span className="font-medium text-gray-900">
                      {' '}
                      فن الكتابة في العالم الرقمي
                    </span>
                  </p>
                </Link>
                <a
                  href="https://form.typeform.com/to/GVXq3p18#email=xxxxx&name=xxxxx&login=xxxxx"
                  target="_blank"
                  className="w-full"
                >
                  <Button className="w-full bg-purple-700 hover:bg-purple-800 text-white transition-all duration-200 mt-4">
                    تحتاج إلى مساعدة؟
                  </Button>
                </a>
              </section>

              {/* Add this new Topics Section */}
              <section className="space-y-4">
                <h2
                  onClick={() => router.push('/explore/topics')}
                  className="text-lg font-semibold text-gray-900 cursor-pointer hover:text-purple-700 transition-colors flex items-center justify-between"
                >
                  موضوعات أخرى
                  <ChevronLeft className="w-5 h-5" />
                </h2>
                <div className="flex flex-wrap gap-2">
                  {isLoadingCategories ? (
                    <div className="flex items-center justify-center">
                      <Loader />
                    </div>
                  ) : (
                    displayedCategories.map((category) => (
                      <span
                        key={category._id}
                        onClick={() => handleInterestSelect(category._id)}
                        className="flex items-center gap-1.5 px-3.5 py-1.5 bg-purple-50 text-sm text-gray-700 rounded-full cursor-pointer hover:bg-purple-100 hover:text-purple-800 transition-all duration-200"
                      >
                        {category.name}
                      </span>
                    ))
                  )}
                </div>
                <div className="flex justify-between gap-2 w-[90%] flex-wrap">
                  <a
                    className="underline text-sm"
                    target="_blank"
                    href="https://start.kitabh.com/en/terms_conditions"
                  >
                    الشروط والأحكام
                  </a>
                  <a
                    className="underline text-sm"
                    target="_blank"
                    href="https://start.kitabh.com/en/privacy_policy"
                  >
                    سياسة الخصوصيّة
                  </a>
                  <a href="#" className=" text-sm">
                    باقة الكاتب
                  </a>
                </div>
              </section>
            </div>
            {token && (
              <span
                onClick={() => {
                  router.push('/text-editor');
                  setOpen(false);
                }}
                className="cursor-pointer  md:hidden flex fixed bottom-14 "
              >
                <Image src={pen} alt="" width={100} height={100} />
              </span>
            )}
            <Modal
              isOpen={open}
              onRequestClose={() => setOpen(false)}
              style={customStyles}
            >
              <div className="w-full  flex flex-col gap-3 p-2">
                <span
                  onClick={() => {
                    router.push('/workshop');
                    setOpen(false);
                  }}
                  className={`cursor-pointer flex flex-row-reverse gap-1 items-center text-[#101729] font-[500] text-[20px]`}
                >
                  <PencilRuler width={25} height={25} />
                  ورش الكتابة
                </span>
                <span
                  onClick={() => {
                    router.push('/save-idea');
                    setOpen(false);
                  }}
                  className={`cursor-pointer flex gap-1 flex-row-reverse items-center  text-[#101729] font-[500] text-[20px]`}
                >
                  <Lightbulb width={25} height={25} />
                  مدوّنة الأفكار
                </span>
                <span
                  onClick={() => {
                    router.push('/text-editor');
                    setOpen(false);
                  }}
                  className={`cursor-pointer flex flex-row-reverse gap-1 items-center  text-[#101729] font-[500] text-[20px]`}
                >
                  <SquarePen width={25} height={25} />
                  اكتب
                </span>
              </div>
            </Modal>
            {openTopicModal && (
              <AddTopicModal
                isOpen={openTopicModal}
                onClose={() => setOpenTopicModal(false)}
                interests={categories?.categories || []}
                interestList={defaultCategories}
                isLoading={isLoadingCategories}
                handleTopicToggle={handleTopicToggle}
              />
            )}
          </div>
        </main>
      )}
    </>
  );
};

export default HomePage;

const AddTopicModal = ({
  isOpen,
  onClose,
  interests,
  interestList,
  isLoading,
  handleTopicToggle,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '90%',
      maxWidth: '500px',
      maxHeight: '550px',
      overflowY: 'auto',
      transform: 'translate(-50%, -50%)',
      scrollbarWidth: 'thin',
      background: 'white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Add Topic Modal"
    >
      <div className="flex flex-col relative gap-4">
        <span
          onClick={onClose}
          className="absolute cursor-pointer top-2 right-2"
        >
          <IoClose />
        </span>
        <span className="text-[20px] font-[600] w-full text-center">
          إضافة موضوع
        </span>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          interestList?.map((category) => {
            // Check if category is in user's followed categories
            const isInterested = interests.some(
              (interest) => interest?._id === category?._id
            );

            return (
              <div
                key={category?._id}
                className="flex w-full justify-between items-center"
              >
                {isInterested ? (
                  <span
                    onClick={() => handleTopicToggle(category?._id)}
                    className="bg-white cursor-pointer rounded-md text-[#8641BD] border border-[#8641BD] py-1 px-4"
                  >
                    إلغاء{' '}
                  </span>
                ) : (
                  <span
                    onClick={() => handleTopicToggle(category?._id)}
                    className="bg-[#8641BD] cursor-pointer rounded-md text-white border py-1 px-4"
                  >
                    المتابعة
                  </span>
                )}
                <span className="w-fit cursor-pointer rounded-xl px-4 py-1">
                  <p className="text-[16px] font-[600]">{category?.name}</p>
                </span>
              </div>
            );
          })
        )}
      </div>
    </Modal>
  );
};
