'use client';

import Image from 'next/image';
import { useRouter } from 'next/navigation';
import {
  Bell,
  Bookmark,
  Crown,
  HelpCircle,
  LogOut,
  Newspaper,
  Settings,
  User,
} from 'lucide-react';
import { signOut } from 'next-auth/react';

import ProfileImage from '@/components/ui/profile-image';

import { avatar } from '../../../../../public/images';

const Dropdown = ({ setOpenDropdown, user, setShowNotifications }) => {
  const router = useRouter();

  const menuItems = [
    {
      icon: <User className="w-5 h-5" />,
      label: 'الملف الشخصي',
      onClick: () => router.push(`/@${user?.user?.username}`),
    },
    {
      icon: <Bookmark className="w-5 h-5" />,
      label: 'المحفوظات',
      onClick: () => router.push('/saved-articles'),
    },
    {
      icon: <Settings className="w-5 h-5" />,
      label: 'الإعدادات',
      onClick: () => router.push('/settings'),
    },
    {
      icon: <HelpCircle className="w-5 h-5" />,
      label: 'تحتاج إلى مساعدة؟',
      onClick: () =>
        window.open(
          'https://form.typeform.com/to/GVXq3p18#email=xxxxx&name=xxxxx&login=xxxxx',
          '_blank'
        ),
    },
    {
      icon: '🚀',
      label: 'شارك معنا في بناء كتابة',
      onClick: () =>
        window.open(
          'https://form.typeform.com/to/YV2Oeknv',
          '_blank',
          'noopener,noreferrer'
        ),
    },
    {
      icon: <LogOut className="w-5 h-5" />,
      label: 'تسجيل الخروج',
      onClick: () => signOut({ callbackUrl: '/' }),
      danger: true,
    },
  ];

  return (
    <>
      {/* Overlay for mobile */}
      <div
        className="fixed inset-0 bg-black/20 z-40 md:hidden"
        onClick={() => setOpenDropdown(false)}
      />

      {/* Dropdown content */}
      <div
        className={`
                fixed md:absolute
                inset-x-0 md:inset-x-auto
                bottom-16 md:bottom-auto
                md:left-0 md:top-[120%]
                bg-white
                rounded-t-2xl md:rounded-xl
                shadow-lg border border-gray-100
                z-50
                transform transition-transform duration-200 ease-out
                translate-y-0 md:translate-y-0
                max-h-[calc(100vh-4rem-4rem)] md:max-h-[410px]
                overflow-y-auto
                md:w-64
            `}
      >
        {/* User Info Section */}
        <div className="sticky top-0 px-4 py-4 border-b border-gray-100 bg-white">
          <div className="flex items-center gap-3">
            <div className="relative w-10 h-10 flex-shrink-0">
              <ProfileImage
                src={user?.user?.profileImage}
                alt="Profile"
                size="sm"
                isPremium={user?.user?.isPremium}
              />
            </div>
            <div className="flex flex-col min-w-0">
              <span className="text-base font-medium text-gray-900 truncate">
                {user?.user?.name || 'User'}
              </span>
              <span className="text-sm text-gray-500">
                @{user?.user?.username || 'username'}
              </span>
            </div>
          </div>
        </div>

        {/* Menu Items */}
        <div className="py-2">
          {menuItems.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                item.onClick();
                setOpenDropdown(false);
              }}
              className={`
                                w-full px-4 py-3 md:py-2.5
                                flex items-center gap-3
                                hover:bg-gray-50 active:bg-gray-100
                                transition-colors
                                ${item.danger ? 'text-red-600' : 'text-gray-700'}
                            `}
            >
              {item.icon}
              <span className="text-sm">{item.label}</span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
